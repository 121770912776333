/* eslint-disable no-console,no-undef,camelcase,no-useless-escape */

const BASE_URL =
process.env.ENVIRONMENT === "production"
  ? "https://api.trainingamigo.com" :
  process.env.ENVIRONMENT === "test"
    ? process.env.API_URL:process.env.ENVIRONMENT === "sandbox"
      ?"https://sandboxapi.woliba.io" : process.env.ENVIRONMENT === "staging"
        ? "https://stagingapi.woliba.io":  "https://dev.api.trainingamigo.com";

const COMPANY_NAME = "Woliba"; //'Woliba' 'Training Amigo';;
const DISPLAY_SIGN_IN = true;
const LOGO_URL = "/public/images/Logo/Woliba.svg";
const WOLIBA_FORM = "Woliba_Form.pdf";

export { BASE_URL, COMPANY_NAME, DISPLAY_SIGN_IN, LOGO_URL, WOLIBA_FORM };
